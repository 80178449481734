import { isEqual } from 'lodash'
import useCurrentUser from 'web-client/hooks/useCurrentUser'
import useFeaturesEnabled from 'web-client/hooks/useFeaturesEnabled'
import type { HREF, TITLE } from './const'
import { MENU_ITEMS } from './const'

export type Menu = { title: TITLE; href: HREF }[]

const useLoggedInMenuItems = () => {
  const { currentUser, isLoggedIn } = useCurrentUser()
  const { featuresEnabled } = useFeaturesEnabled()

  const menu: Menu = []
  if (!isLoggedIn || !currentUser.permissions || !currentUser.company) {
    return menu
  }

  if (currentUser.permissions.isPiiRestricted) {
    return [MENU_ITEMS.FLEET]
  }

  if (currentUser.permissions.isPartner) {
    menu.push(...MENU_ITEMS.DISPATCH)
  }
  if (currentUser.permissions.isClient) {
    menu.push(MENU_ITEMS.FLEET)
  }
  if (currentUser.permissions.isRoot) {
    menu.push(MENU_ITEMS.ROOT)
  }
  if (currentUser.permissions.isSwoopDispatcher) {
    menu.push(MENU_ITEMS.SWOOP_DISPATCH)
  }

  const isTaskManagementFeatureEnabled = !!featuresEnabled?.TaskManagement

  if (isTaskManagementFeatureEnabled) {
    menu.push(MENU_ITEMS.TASKS)
  }

  const isInvoicingFeatureEnabled = !!featuresEnabled?.Invoicing
  const isInvoicingMenuItemEnabled =
    isInvoicingFeatureEnabled &&
    ((!currentUser.permissions.isSuperCompany && currentUser.permissions.isAdmin) ||
      currentUser.permissions.isRoot) &&
    !currentUser.permissions.isClient // Hiding new invoices tab for fleet and in-house fleet
  if (isInvoicingMenuItemEnabled) {
    menu.push(MENU_ITEMS.INVOICING)
  }

  const isReviewFeedFeatureEnabled = !!featuresEnabled?.ReviewFeed
  if (isReviewFeedFeatureEnabled) {
    menu.push(MENU_ITEMS.REVIEW_FEED)
  }

  const isHideReportingFeatureDisabled = featuresEnabled && !featuresEnabled.HideReporting
  if (
    currentUser.permissions.isAdmin &&
    currentUser.permissions.isInsightsEnabled &&
    isHideReportingFeatureDisabled
  ) {
    menu.push(MENU_ITEMS.INSIGHTS)
  }

  const isResourcesMenuItemEnabled = currentUser.permissions.isPartner
  if (isResourcesMenuItemEnabled) {
    menu.push(MENU_ITEMS.RESOURCES)
  }

  if (currentUser.company.resourcesUrl) {
    menu.push(MENU_ITEMS.CLIENT_RESOURCES)
  }

  const isOnlyDriver =
    currentUser.permissions.isDriver &&
    !currentUser.permissions.isAdmin &&
    !currentUser.permissions.isDispatcher
  if (isOnlyDriver) {
    const onlyDriverMenu = menu.filter(
      (item) => !isEqual(item, MENU_ITEMS.REVIEW_FEED) && !isEqual(item, MENU_ITEMS.DISPATCH[1])
    )
    return onlyDriverMenu
  }
  return menu
}

export default useLoggedInMenuItems
