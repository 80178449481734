import type { ReactNode } from 'react'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Portal from 'web-client/components/Portal'
import { AnimatePresence } from 'framer-motion'
import createUUID from 'web-client/utils/createUUID'
import ErrorBoundary from 'shared/components/ErrorBoundary'
import ErrorBoundaryModal from 'design-system/components/Modals/ErrorBoundaryModal'
import dataDog from 'shared/utils/logging/integrations/datadog'
import type { Context } from '@datadog/browser-core'
import ModalContext from './ModalContext'
import ModalPropsProvider from './ModalPropsProvider'

type ModalId = number | string

type ModalTiming = {
  startTime: number
  endTime?: number
  time?: number
  modalType?: string
  sourceName?: string
}

export const bodyOverflowEffect = (modalsExist: boolean) => {
  if (modalsExist && document?.body) {
    const originalOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = originalOverflow
    }
  }
}

export type ModalItem = { id: ModalId; modal: React.ReactElement }

type Props = {
  children?: ReactNode
}

const ModalProvider = ({ children }: Props) => {
  const [modals, setModals] = useState<Array<ModalItem>>([])
  const [enqueuedToClose, setEnqueuedToClose] = useState<ModalId[]>([])

  const modalsExist = modals.length > 0
  useLayoutEffect(() => bodyOverflowEffect(modalsExist), [modalsExist])

  const closeModal = useCallback((id?: ModalId) => {
    setModals((prevModals) => {
      const clonedModals = [...prevModals]
      const modalToClose = clonedModals.find((modal) => modal.id === id)
      if (modalToClose) {
        setEnqueuedToClose((prevQueue) => [...prevQueue, modalToClose.id])
        return clonedModals.filter((modal) => modal.id !== id)
      }

      if (!id && clonedModals.length > 0) {
        const splicedModal = clonedModals.splice(-1)
        setEnqueuedToClose((prevQueue) => [...prevQueue, splicedModal[0]?.id])
        return clonedModals
      }

      return clonedModals
    })
  }, [])

  useEffect(() => {
    if (enqueuedToClose.length === 0) {
      return
    }

    const timeout = setTimeout(() => {
      setEnqueuedToClose((prevQueue) => prevQueue.slice(1))
    }, 200) // delay for animation duration

    return () => clearTimeout(timeout)
  }, [enqueuedToClose])

  const closeAllModals = useCallback(() => {
    setEnqueuedToClose(modals.map((modal) => modal.id))
  }, [modals])

  const openModal = useCallback((nextModal: React.ReactElement, id: ModalId = createUUID()) => {
    setModals((currModals) => [...currModals, { id, modal: nextModal }])
    return id
  }, [])

  const modalIsOpen = useCallback(
    (modalId: ModalId) => !!modals.find((modal) => modalId === modal.id),
    [modals]
  )

  const modalIsTop = useCallback(
    (modalId: ModalId) => {
      const topModalId = modals[modals.length - 1]?.id
      return modalId === topModalId
    },
    [modals]
  )

  const modalTiming = useRef<ModalTiming | null>()
  useEffect(() => {
    if (modals.length > 0) {
      const { modal } = modals[modals.length - 1]
      modalTiming.current = {
        startTime: Date.now(),
        // @ts-expect-error
        modalType: modal.type?.name,
        // @ts-expect-error
        sourceName: modal._source?.fileName,
      }
    } else if (modals.length === 0 && modalTiming.current) {
      modalTiming.current.endTime = Date.now()
      modalTiming.current.time =
        (modalTiming.current.endTime - modalTiming.current.startTime) / 1000
      dataDog.logDebug('Modal was closed', {
        ...modalTiming.current,
        context: 'ModalProvider',
      } as Context)
      modalTiming.current = null
    }
  }, [modals])

  const handleError = (modalId: ModalId, isEnqueuedToClose: boolean) => (
    <ErrorBoundaryModal enqueuedToClose={isEnqueuedToClose} id={String(modalId)} />
  )

  const value = useMemo(
    () => ({
      closeAllModals,
      closeModal,
      modalIsOpen,
      modalIsTop,
      openModal,
      modals,
    }),
    [closeAllModals, closeModal, modalIsOpen, modalIsTop, openModal, modals]
  )

  return (
    <ModalContext.Provider value={value}>
      {children}
      <AnimatePresence initial={false}>
        {modals.map(({ id, modal }) => {
          const isEnqueuedToClose = enqueuedToClose.includes(id)
          const clonedModal = React.cloneElement(modal, {
            enqueuedToClose: isEnqueuedToClose,
            id,
          })
          return (
            <Portal id="modals" key={id}>
              <ModalPropsProvider id={id}>
                <ErrorBoundary errorRender={() => handleError(id, isEnqueuedToClose)}>
                  {clonedModal}
                </ErrorBoundary>
              </ModalPropsProvider>
            </Portal>
          )
        })}
      </AnimatePresence>
    </ModalContext.Provider>
  )
}

export default ModalProvider
