import React from 'react'
import { Global, css } from '@emotion/react'

import colors from 'design-system/tokens/colors'
import fonts from 'design-system/tokens/fonts'
import fontSizes from 'design-system/tokens/fontSizes'

const styles = css`
  * {
    box-sizing: inherit;
  }

  html {
    scroll-behavior: smooth;
    @media (prefers-reduced-motion) {
      scroll-behavior: initial;
    }
  }

  body {
    box-sizing: border-box;
    color: ${colors.black};
    font-family: ${fonts.body}, sans-serif;
    font-size: ${fontSizes.body};
    line-height: 20px;
    margin: 0;
  }

  var {
    font-style: inherit;
  }

  a,
  button {
    /*
     * Override Bootstrap's default focus style
     * https://github.com/joinswoop/swoop/pull/42611#discussion_r1943294023
     */
    &:focus:is(:focus-visible) {
      outline: auto ${colors.primary};
      outline-offset: 2px;
    }
  }

  input[type='checkbox']:focus:is(:focus-visible) {
    outline: auto ${colors.primary};
    outline-offset: 2px;
  }

  input,
  select,
  textarea {
    &:focus:is(:focus-visible) {
      outline: auto ${colors.primary};
    }
    &[aria-required='true']:focus:is(:focus-visible) {
      outline-offset: 4px;
    }
  }
`

const GlobalStyles = () => <Global styles={styles} />

export default GlobalStyles
