export const enum TITLE {
  DISPATCH_DASHBOARD = 'dashboard',
  DISPATCH_FLEET = 'fleet',
  FLEET = 'dashboard',
  ROOT = 'dashboard',
  SWOOP_DISPATCH = 'dashboard',
  INVOICING = 'invoices',
  REVIEW_FEED = 'reviews',
  INSIGHTS = 'insights',
  STORAGE = 'storage',
  TASKS = 'tasks',
  CLIENT_RESOURCES = 'resources',
  RESOURCES = 'resources',
}

export const enum HREF {
  DISPATCH_DASHBOARD = 'dispatch',
  DISPATCH_FLEET = 'fleet',
  FLEET = 'dashboard',
  ROOT = 'dispatch',
  SWOOP_DISPATCH = 'dispatch',
  INVOICING = 'invoices',
  REVIEW_FEED = 'reviews',
  INSIGHTS = 'insights',
  STORAGE = 'storage',
  TASKS = 'tasks',
  CLIENT_RESOURCES = 'resources',
  RESOURCES = 'resources',
}

export const MENU_ITEMS = {
  DISPATCH: [
    { title: TITLE.DISPATCH_DASHBOARD, href: HREF.DISPATCH_DASHBOARD },
    { title: TITLE.DISPATCH_FLEET, href: HREF.DISPATCH_FLEET },
  ],
  FLEET: { title: TITLE.FLEET, href: HREF.FLEET },
  ROOT: { title: TITLE.ROOT, href: HREF.ROOT },
  SWOOP_DISPATCH: { title: TITLE.SWOOP_DISPATCH, href: HREF.SWOOP_DISPATCH },
  INVOICING: { title: TITLE.INVOICING, href: HREF.INVOICING },
  REVIEW_FEED: { title: TITLE.REVIEW_FEED, href: HREF.REVIEW_FEED },
  INSIGHTS: { title: TITLE.INSIGHTS, href: HREF.INSIGHTS },
  STORAGE: { title: TITLE.STORAGE, href: HREF.STORAGE },
  TASKS: { title: TITLE.TASKS, href: HREF.TASKS },
  CLIENT_RESOURCES: { title: TITLE.CLIENT_RESOURCES, href: HREF.CLIENT_RESOURCES },
  RESOURCES: { title: TITLE.RESOURCES, href: HREF.RESOURCES },
}

/**
 * Breakpoints were built with the worst case possible at August 2022.
 * The worst case possible is composed of the followings:
 * Text buttons as links:
 * DASHBOARD FLEET INVOICES REVIEWS INSIGHTS STORAGE RESOURCES
 * Icons and Buttons as links:
 * HelpCenterIcon SettingsIcon DropNavButton
 */
export const breakpointHideMenuLinksWidth = 1210
export const breakpointHideMenuIcons = 1310

export const DIMENSIONS = {
  HEIGHT: '60px',
  HIGHLIGHT_HEIGHT: '45px',
  BREAKPOINT_HIDE_DROPNAV: '400px',
  BREAKPOINT_REVIEWS_CONTROLS: '500px',
  BREAKPOINT_REVIEWS_SEARCH: '750px',
  BREAKPOINT_DASHBOARD_TAB: '400px',
  BREAKPOINT_DASHBOARD_SEARCH: '600px',
  BREAKPOINT_SETTINGS_CONTROLS: '500px',
  BREAKPOINT_SETTINGS_TAB: '900px',
  BREAKPOINT_SETTINGS_SEARCH: '1200px',
  BREAKPOINT_SETTINGS_PLACES_SEARCH: '800px',
  BREAKPOINT_HIDE_MENU_LINKS: `${breakpointHideMenuLinksWidth}px`,
  BREAKPOINT_HIDE_MENU_ICONS: `${breakpointHideMenuIcons}px`,
} as const

export const enum ISSC_SITE_STATUS {
  DISCONNECTED = 'disconnected',
  UNREGISTERED = 'unregistered',
  REGISTERING = 'registering',
  REGISTERED = 'registered',
  LOGGING_IN = 'logging_in',
  LOGGED_IN = 'logged_in',
  LOGGING_OUT = 'logging_out',
  DEREGISTERING = 'deregistering',
  REGISTER_FAILED = 'register_failed',
  PASSWORD_INCORRECT = 'password_incorrect',
}

export const enum ClientId {
  GCO = 'GCO',
  GCOAPI = 'GCOAPI',
}

export const enum CompanyStatus {
  UP = 'Up',
  DOWN = 'Down',
}

export const enum AccountStatus {
  Disconnected = '(Disconnected)',
  Unknown = '(Unknown)',
  Empty = '',
}
