const swoopBlue = '#0074E2'

const reds = {
  l1: '#FF5D4E',
  l2: '#ED0000',
  l3: '#D20000',
  l4: '#B60000',
}

const greens = {
  l1: '#00AC1E',
  l2: '#008918',
  l3: '#007114',
  l4: '#005910',
}

const grays = {
  l1: '#5a5c5f',
  l2: '#767676',
  l3: '#949494',
  l4: '#c5c5c5',
  l5: '#e1e1e1',
  l6: '#f2f2f2',
  l7: '#fafafa',
}

const colors = {
  gray: grays.l2,
  alerts: {
    green: greens.l2,
    red: reds.l2,
    orange: '#ffa130',
    yellow: '#ffbe4f',
  },
  banner: {
    green: '#ECFAF2',
    red: '#FFF0EE',
    blue: '#EAF8FC',
    yellow: '#FFF8ED',
    gray: '#FAFAFA',
  },
  statuses: {
    yellow: '#e0b7fb',
    pink: '#e0b7fb',
    green: '#6beda5',
    purple: '#acb8fe',
    orange: '#fcc37f',
    red: reds.l1,
  },

  blues: {
    highlight: '#F5FBFE',
    hover: '#0057A9',
    light: '#7fbce2',
    vibrant: '#59cbe8',
    midrange: '#2ca1d8',
    dark: '#0077c8',
  },
  grays: {
    ...grays,
    primary: grays.l2,
  },
  greens,
  reds,
  swoopRed: reds.l1,
  black: '#272729',
  white: '#fff',
  primary: swoopBlue,
} as const

export type SwoopColor =
  | typeof colors.swoopRed
  | (typeof colors.blues)[keyof typeof colors.blues]
  | (typeof colors.banner)[keyof typeof colors.banner]
  | typeof colors.black
  | typeof colors.white
  | typeof colors.primary

export default colors
