/* eslint-disable relay/unused-fields -- it makes sense to fetch common used fields to be used everywhere so a single request can get common properties */
import { commitLocalUpdate } from 'relay-runtime'

import { graphql, useQuery } from 'relay-hooks'
import { useCallback, useMemo } from 'react'
import { useRelayEnvironment } from 'react-relay'

import userModel from 'shared/utils/user'

import type { RoleType } from 'graphql/enums'

import { getUUID } from 'consumer-mobile-web/utils/jobutils'
import type { useCurrentUserQuery } from './__generated__/useCurrentUserQuery.graphql'
import type { useCurrentUserIsLoggedInQuery } from './__generated__/useCurrentUserIsLoggedInQuery.graphql'

export type Role = keyof typeof RoleType
export type User = Omit<useCurrentUserQuery['response']['viewer'], 'roles'> & {
  roles: Array<Role>
}

let initializing = false

const useCurrentUser = () => {
  const environment = useRelayEnvironment()
  const mobileAuth = getUUID()

  const setIsLoggedIn = useCallback(
    (isLoggedIn: boolean) => {
      commitLocalUpdate(environment, (store) => {
        store.getRoot().setValue(isLoggedIn, 'isLoggedIn')
      })
    },
    [environment]
  )

  if (!initializing) {
    initializing = true
    const isAuthenticated = !mobileAuth && userModel.isAuthenticated()
    setIsLoggedIn(isAuthenticated)
  }

  const { data: isLoggedInData } = useQuery<useCurrentUserIsLoggedInQuery>(
    graphql`
      query useCurrentUserIsLoggedInQuery {
        ... on Query {
          __typename
          isLoggedIn
        }
      }
    `,
    {},
    { fetchPolicy: 'store-only' }
  )

  const isLoggedIn = isLoggedInData?.isLoggedIn

  const { data, isLoading } = useQuery<useCurrentUserQuery>(
    graphql`
      query useCurrentUserQuery {
        viewer {
          ... on User {
            id
            swcid
            company {
              id
              swcid
              distanceUnit
              resourcesUrl
              inHouse
              language
              name
              type
              ...useCompanyInfo_company
            }
            email
            ctiStatus {
              id
              extension
              status
            }
            language
            name
            permissions {
              isRoot
              isDriver
              isAdmin
              isDispatcher
              isAnsweringService
              isPartner
              isClient
              isSuperCompany
              isSwoopDispatcher
              isPiiRestricted
              isCtiEnabled
              isInsightsEnabled
            }
            roles {
              nodes {
                id
                name
              }
            }
            userName
          }
        }
      }
    `,
    {},
    { skip: !isLoggedIn }
  )

  let endpoint = 'partner'
  if (data?.viewer?.permissions?.isSuperCompany) {
    endpoint = 'root'
  }
  if (data?.viewer?.permissions?.isClient) {
    endpoint = 'fleet'
  }

  const permissions = useMemo(
    () =>
      data?.viewer.permissions
        ? {
            isOnlyDriver:
              data.viewer.permissions.isDriver &&
              !data.viewer.permissions.isAdmin &&
              !data.viewer.permissions.isDispatcher,
            ...data.viewer.permissions,
          }
        : ({} as NonNullable<
            useCurrentUserQuery['response']['viewer']['permissions'] & { isOnlyDriver: boolean }
          >),
    [data?.viewer.permissions]
  )

  const currentUser = useMemo(
    () => ({
      ...data?.viewer,
      permissions,
      company:
        data?.viewer?.company ??
        ({} as NonNullable<useCurrentUserQuery['response']['viewer']['company']>),
      roles: data?.viewer.roles?.nodes.map((node) => node.name as Role) ?? [],
    }),
    [data?.viewer, permissions]
  )

  return {
    currentUser,
    isLoading,
    isLoggedIn,
    setIsLoggedIn,
    endpoint,
  }
}

export default useCurrentUser
