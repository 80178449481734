import React from 'react'
import styled from '@emotion/styled'

import useCompanyInfo from 'web-client/components/JobDetails/JobDetailsMap/useCompanyInfo'
import swoopLogo from '../images/swoop_logo.svg'
import { DIMENSIONS } from '../LoggedIn/const'

const LogoLink = styled.a`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 28px;

  @media screen and (max-width: ${DIMENSIONS.BREAKPOINT_HIDE_DROPNAV}) {
    padding: 0 5px;
  }
`

const CustomLogo = styled.span`
  font-size: 0;
  height: 100%;
`

const CustomLogoHeight = '36px'

const CompanyLogoImg = styled.img`
  display: inline-block;
  height: ${CustomLogoHeight};
  margin: 0;
  max-height: 100%;
  vertical-align: middle;
  width: auto;

  @media screen and (max-width: ${DIMENSIONS.BREAKPOINT_HIDE_DROPNAV}) {
    height: calc(${CustomLogoHeight} / 2);
  }
`

const SwoopHeaderLogoHeight = '20px'
const SwoopHeaderLogoWidth = '171px'

const SwoopHeaderLogo = styled.i`
  height: ${SwoopHeaderLogoHeight};
  width: ${SwoopHeaderLogoWidth};
  display: initial;
  background: url(${swoopLogo});

  @media screen and (max-width: ${DIMENSIONS.BREAKPOINT_HIDE_DROPNAV}) {
    height: calc(${SwoopHeaderLogoHeight} / 2);
    width: calc(${SwoopHeaderLogoWidth} / 2);
  }
`

type CompanyLogoProps = {
  useSwoopLogo?: boolean
}

const CompanyLogo = ({ useSwoopLogo = false }: CompanyLogoProps) => {
  const companyInfo = useCompanyInfo()
  if (!companyInfo && !useSwoopLogo) {
    return null
  }

  return (
    <LogoLink href="/">
      {companyInfo?.logoUrl ? (
        <CustomLogo>
          <CompanyLogoImg alt="Company Logo" src={companyInfo.logoUrl} />
        </CustomLogo>
      ) : (
        <SwoopHeaderLogo />
      )}
    </LogoLink>
  )
}
export default CompanyLogo
